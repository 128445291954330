<template>
  <!-- NAME[epic=动销] 付费陈列统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-input v-model="form.keyword" placeholder="活动名称"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.prop == 'expect'">
            {{ row.expected }}/{{ row.expect }}
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="操作">
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handlePhone($index, row)"
          >
            导出照片
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleList($index, row)"
          >
            导出报表
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { summaryList } from '@/api/payListErr'
  import { downloadFile, postAction } from '@/api/Employee'
  export default {
    name: 'ListSummary',
    components: {},
    data() {
      return {
        loading: false,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        time: [],
        // 1不启用 0启用
        statusList: [
          {
            id: 1,
            name: '不启用',
          },
          {
            id: 0,
            name: '启用',
          },
        ],
        form: {
          pageNo: 1, //
          pageSize: 10, //
          sort: '', //
          order: '', //
          status: '', //状态(1不启用 0启用)
          keyword: '', //名称
          start_time: '', //
          end_time: '', //
        },
        list: [],
        checkList: [
          '活动名称',
          '陈列开始时间',
          '陈列结束时间',
          '陈列门店家数',
          '已终止',
          '陈列费用（已支出/预支出）',
        ],
        columns: [
          {
            order: 1,
            label: '活动名称',
            prop: 'name',
            width: '',
          },
          {
            order: 2,
            label: '陈列开始时间',
            prop: 'add_start_time',
            width: '',
          },
          {
            order: 3,
            label: '陈列结束时间',
            prop: 'add_end_time',
            width: '',
          },
          {
            order: 4,
            label: '陈列门店家数',
            prop: 'ok_cust_num',
            width: '',
          },
          {
            order: 5,
            label: '已终止',
            prop: 'stop_cust_num',
            width: '90px',
          },
          {
            order: 6,
            label: '陈列费用（已支出/预支出）',
            prop: 'expect',
            width: '190px',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true

        let { data, msg, code, totalCount } = await summaryList(this.form)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handlePhone(index, row) {
        postAction('/baseAdmin/export/add-export', {
          type: 1,
          ex_data: JSON.stringify({
            id: row.id,
            type: 2,
            is_agreement: 1,
          }),
        }).then((r) => {
          // ? 导出照片
          this.$message.success('导出中,请在导入/导出列表中查看')
        })
      },
      handleList(index, row) {
        // exportList({ id: row.id }).then((res) => {
        //   console.log(res)
        // })
        postAction('/baseAdmin/export/add-export', {
          type: 2,
          ex_data: JSON.stringify({ id: row.id }),
        }).then((r) => {
          this.$message.success('导出中,请在导入/导出列表中查看')
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
